import {FilterComponentTypes} from './filter-component-types';

const syllableTypeOptions = [
  {name: 'R-Controlled', value: 'R-Controlled'},
  {name: 'Vowel Team', value: 'Vowel Team'},
  {name: 'Magic E', value: 'Magic E'},
  {name: 'Consonant-le', value: 'Consonant-le'},
  {name: 'Open', value: 'Open'},
  {name: 'Closed', value: 'Closed'},
];

export const types = {
  isPhonetic: 'boolean',
  isNonsense: 'array',
  nLetters: 'object',
  nSyllables: 'object',
  exactWords: 'array',
  excludeWords: 'array',
  wordContains: 'array',
  wordDoesNotContain: 'array',
  wordBeginsWith: 'array',
  wordDoesNotBeginWith: 'array',
  wordEndsWith: 'array', 
  wordDoesNotEndWith: 'array',
  anyGrapheme: 'array',
  doesNotContainGrapheme: 'array',
  firstGrapheme: 'array',
  lastGrapheme: 'array',
  nSounds: 'object',
  syldivRules: 'array',
  quickExclude: 'array',
  quickInclude: 'array',
  onsetLetters: 'array',
  rimeLetters: 'array',
  sylLetters: 'array',
  initialblendArray: 'array',
  endingblendArray: 'array',
  cvcPatterns: 'array',
  suffixes: 'array',
  prefixes: 'array',
  soundPairing: 'object',
  sylTypes: 'object',
  exactTiles: 'array',
}

export const FilterTypes = [
  {
    title: 'Phonetic / Sight Workbook',
    description: 'Select \'Phonetic\' to only search for words that fit common phonics rules.  Select \'Sight\' to search for all words, both phonetic and non-phonetic.  \'Sight\' workbooks have certain limitations in the activities because the words are not broken down into their individual sounds:',
    type: FilterComponentTypes.radio,
    name: 'isPhonetic',
    required: true,
    currentVal: true,
    userKnowsWords: true,
    options: [
      {name: 'Phonetic', value: true},
      {name: 'Sight', value: false},
    ],
    filterUrl: 'https://videos.sproutvideo.com/embed/709ddfb71f1ce6c2f9/f6764cad583f4d49'
  },
  {
    title: 'Nonsense / Real Words',
    description: 'Search for nonsense words, real words, or both:',
    type: FilterComponentTypes.quickCheckbox,
    name: 'isNonsense',
    currentVal: [],
    required: true,
    visible: true,
    options: [
      {name: 'Real Words', value: 'real', selected: false},
      {name: 'Nonsense Words', value: 'nonsense', selected: false}
    ],
    filterUrl: 'https://videos.sproutvideo.com/embed/799ddfb71f1ce6c7f0/c869441089161eeb'
  },

  {
    title: 'Number of Letters',
    description: 'Find words that have between 1 and 20 letters:',
    type: FilterComponentTypes.adjustable,
    name: 'nLetters',
    currentVal: {gte: 3, lte: 7},
    // NOTE: This is like this due to some Barton related stuff
    maxNum: 50,
    label: 'letters',
    required: false,
    visible: true,
    filterUrl: 'https://videos.sproutvideo.com/embed/ac9ddfb71f1ce6c625/1c8ef0a3b96cc2bb'
  },
  {
    title: 'Number of Syllables',
    description: 'Find words that have between 1 and 10 syllables:',
    type: FilterComponentTypes.adjustable,
    name: 'nSyllables',
    maxNum: 15,
    currentVal: {gte: 1, lte: 4},
    label: 'syllables',
    required: false,
    visible: true,
    filterUrl: 'https://videos.sproutvideo.com/embed/069ddfb71f1ce6c48f/767ad2ecb6692430'
  },
  {
    title: 'Exact Words',
    description: 'Find specific words by typing them in the following box or by selecting them from your search results:',
    type: FilterComponentTypes.textareaLarge,
    name: 'exactWords',
    currentVal: [],
    userKnowsWords: true,
    visible: false,
    example: "For example: and, in, that",
    filterUrl: 'https://videos.sproutvideo.com/embed/709ddfb71f1ce7c3f9/554f5142e5889287'
  },
  {
    title: 'Exclude Words',
    description: 'Filter out specific words from your search results by typing them in the following box or by selecting them from your search results:',
    type: FilterComponentTypes.textarea,
    name: 'excludeWords',
    currentVal: [],
    visible: false,
    example: "For example: and, in, that",
    filterUrl: 'https://videos.sproutvideo.com/embed/a79ddfb71f1ce7c02e/6f9a9e50746a93b0'
  },
  {
    title: 'Word Contains',
    description: 'Find words that contain the following letters:',
    alert: 'Using this filter card may slow down your search significantly.  We recommend using another filter if possible.',
    type: FilterComponentTypes.textarea,
    name: 'wordContains',
    currentVal: [],
    visible: false,
    example: "For example: th, o, a",
    filterUrl: 'https://videos.sproutvideo.com/embed/709ddfb71f1ce1c5f9/04217eace2562cb2'
  },
  {
    title: 'Word Does Not Contain',
    description: 'Find words that do not contain the following letters:',
    alert: 'Using this filter card may slow down your search significantly.  We recommend using another filter if possible.',
    type: FilterComponentTypes.textarea,
    name: 'wordDoesNotContain',
    currentVal: [],
    visible: false,
    example: "For example: a, in, ha",
    filterUrl: 'https://videos.sproutvideo.com/embed/a79ddfb71f1ce1c62e/66fb22a2e3234245'
  },
  {
    title: 'Word Begins With',
    description: 'Find words that begin with the following letters:',
    alert: 'Using this filter card may slow down your search significantly.  We recommend using another filter if possible.',
    type: FilterComponentTypes.textarea,
    name: 'wordBeginsWith',
    currentVal: [],
    visible: false,
    example: "For example: a, i, tha",
    filterUrl: 'https://videos.sproutvideo.com/embed/4d9ddfb71f1ce1c4c4/ad1c439c6c69435e'
  },
  {
    title: 'Word Does Not Begin With',
    description: 'Find words that do not begin with the following letters:',
    alert: 'Using this filter card may slow down your search significantly.  We recommend using another filter if possible.',
    type: FilterComponentTypes.textarea,
    name: 'wordDoesNotBeginWith',
    currentVal: [],
    visible: false,
    example: "For example: a, i, tha",
    filterUrl: 'https://videos.sproutvideo.com/embed/4d9ddfb71f1ce1c4c4/ad1c439c6c69435e'
  },
  {
    title: 'Word Ends With',
    description: 'Find words that end with the following letters:',
    alert: 'Using this filter card may slow down your search significantly.  We recommend using another filter if possible.',
    type: FilterComponentTypes.textarea,
    name: 'wordEndsWith',
    // TODO: Fix this at some point. The issue is that this should be an array and not a string. What is saving it is that
    // that the "word-filter-text-area" component is converting it to an array before sending it back to query the backend.
    // The issue with messing with this is the user's workbook data may have this as a string instead of an array which could cause
    // issues if I change this now
    currentVal: '',
    visible: false,
    example: "For example: s, ch, ing",
    filterUrl: 'https://videos.sproutvideo.com/embed/ea9ddfb71f1ce1c763/eeaf917e1a00b4ac'
  },
  {
    title: 'Word Does Not End With',
    description: 'Find words that do not end with the following letters:',
    alert: 'Using this filter card may slow down your search significantly.  We recommend using another filter if possible.',
    type: FilterComponentTypes.textarea,
    name: 'wordDoesNotEndWith',
    currentVal: [],
    visible: false,
    example: "For example: s, ch, ing",
    filterUrl: 'https://videos.sproutvideo.com/embed/ea9ddfb71f1ce1c763/eeaf917e1a00b4ac'
  },
  {
    title: 'Any Grapheme',
    description: 'Find words that contain at least one of the following graphemes:',
    type: FilterComponentTypes.textarea,
    name: 'anyGrapheme',
    currentVal: '',
    visible: false,
    example: "For example: a, ll, ing",
    filterUrl: 'https://videos.sproutvideo.com/embed/799ddfb71f1ce7c6f0/e3d50d44bd53a118'
  },
  {
    title: 'Does Not Contain Grapheme',
    description: 'Find words that do not contain any of the following graphemes:',
    type: FilterComponentTypes.textarea,
    name: 'doesNotContainGrapheme',
    currentVal: '',
    visible: false,
    example: "For example: a, i, th",
    filterUrl: 'https://videos.sproutvideo.com/embed/d39ddfb71f1ce7c45a/5554ce2f11bfc110'
  },
  {
    title: 'First Grapheme',
    description: 'Find words that begin with any of the following graphemes:',
    type: FilterComponentTypes.textarea,
    name: 'firstGrapheme',
    currentVal: '',
    visible: false,
    example: "For example: a, i, th",
    filterUrl: 'https://videos.sproutvideo.com/embed/ea9ddfb71f1ce7c163/3027661108307a02'
  },
  {
    title: 'Last Grapheme',
    description: 'Find words that end with any of the following graphemes:',
    type: FilterComponentTypes.textarea,
    name: 'lastGrapheme',
    currentVal: '',
    visible: false,
    example: "For example: a, ll, ing",
    filterUrl: 'https://videos.sproutvideo.com/embed/449ddfb71f1ce7cfcd/ea98db8154bf3083'
  },
  {
    title: 'Number of Sounds',
    description: 'Find words that have between 1 and 12 sounds:',
    type: FilterComponentTypes.adjustable,
    name: 'nSounds',
    maxNum: 12,
    currentVal: {gte: 1, lte: 12},
    label: 'sounds',
    visible: false,
    filterUrl: 'https://videos.sproutvideo.com/embed/d39ddfb71f1ce6c55a/96f1117077c19903'
  },
  {
    title: 'Syllable Division',
    description: 'Find words that fit the following common syllable division rules:',
    type: FilterComponentTypes.quickCheckbox,
    name: 'syldivRules',
    currentVal: [],
    valProperty: '$in',
    visible: false,
    options: [
      {name: 'Two Syllable Compound Words', value: 'CW', selected: false},
      {name: 'Include One Syllable Words', value: '1syll', selected: false},
      {name: 'Consonant-LE Syllables', value: 'CLE', selected: false},
      {name: 'VC/CCV Patterns', value: 'VC-CCV', selected: false},
      {name: 'VCC/CV Patterns', value: 'VCC-CV', selected: false},
      {name: 'VC/CV Patterns', value: 'VC-CV', selected: false},
      {name: 'V/CV Patterns', value: 'V-CV', selected: false},
      {name: 'VC/V Patterns', value: 'VC-V', selected: false},
      {name: 'V/V Patterns', value: 'V-V', selected: false},
      {name: 'Suffixes', value: 'suffix', selected: false},
      {name: 'Prefixes', value: 'prefix', selected: false}
    ],
    filterUrl: 'https://videos.sproutvideo.com/embed/d39ddfb71f1ce1c25a/a2d05052167e37bc'
  },
  {
    title: 'Quick Exclude',
    description: 'Filter out words with any of the following attributes:',
    type: FilterComponentTypes.quickCheckbox,
    name: 'quickExclude',
    currentVal: [],
    visible: false,
    multipleCategories: false,
    options: [
      {name: '1-1-1 Spelling Rule', value: '1-1-1', selected: false},
      {name: '2-1-1 Spelling Rule', value: '2-1-1', selected: false},
      {name: 'Compound Words', value: 'compoundword', selected: false},
      {name: 'Consonant Digraphs', value: 'cdt', selected: false},
      {name: 'Double Letters', value: 'doubleletter', selected: false},
      {name: 'Ending Blends', value: 'endingblend', selected: false},
      {name: 'Initial Blends', value: 'initialblend', selected: false},
      {name: 'Prefixes', value: 'prefix', selected: false},
      {name: 'Suffixes', value: 'suffix', selected: false},
      {name: 'e-Dropping Spelling Rule', value: 'edropping', selected: false},
      {name: 'f to v Plural Rule', value: 'ftov', selected: false},
      {name: 'o Plural Rule', value: 'oplural', selected: false},
      {name: 'y Plural Rule', value: 'yplural', selected: false},
      {name: 'y-Changing Suffix Rule', value: 'ychanging', selected: false},
      {name: 'Proper Nouns', value: 'propernoun', selected: false},

    ],
    filterUrl: 'https://videos.sproutvideo.com/embed/ea9ddfb71f1ce6c063/10faaa275fae57a2'
  },
  {
    title: 'Quick Include',
    description: 'Find words with any of the following attributes:',
    type: FilterComponentTypes.quickCheckbox,
    name: 'quickInclude',
    currentVal: [],
    visible: false,
    multipleCategories: false,
    options: [
      {name: '1-1-1 Spelling Rule', value: '1-1-1', selected: false},
      {name: '2-1-1 Spelling Rule', value: '2-1-1', selected: false},
      {name: 'Compound Words', value: 'compoundword', selected: false},
      {name: 'Consonant Digraphs', value: 'cdt', selected: false},
      {name: 'Double Letters', value: 'doubleletter', selected: false},
      {name: 'Ending Blends', value: 'endingblend', selected: false},
      {name: 'Initial Blends', value: 'initialblend', selected: false},
      {name: 'Prefixes', value: 'prefix', selected: false},
      {name: 'Suffixes', value: 'suffix', selected: false},
      {name: 'e-Dropping Spelling Rule', value: 'edropping', selected: false},
      {name: 'f to v Plural Rule', value: 'ftov', selected: false},
      {name: 'o Plural Rule', value: 'oplural', selected: false},
      {name: 'y Plural Rule', value: 'yplural', selected: false},
      {name: 'y-Changing Suffix Rule', value: 'ychanging', selected: false},
      {name: 'Proper Nouns', value: 'propernoun', selected: false},

    ],
    filterUrl: 'https://videos.sproutvideo.com/embed/119ddfb71f1ce6cf98/cea4e81fd5c6db3e'
  },
  {
    title: 'Onset Letters',
    description: 'Find words with the following onset letters:',
    type: FilterComponentTypes.textarea,
    name: 'onsetLetters',
    currentVal: [],
    visible: false,
    example: "For example: bl, r, ch",
    filterUrl: 'https://videos.sproutvideo.com/embed/4d9ddfb71f1ce6c3c4/9cf523316e77078c'
  },
  {
    title: 'Rime Letters',
    description: 'Find words with the following onset letters:',
    type: FilterComponentTypes.textarea,
    name: 'rimeLetters',
    currentVal: [],
    visible: false,
    example: "For example: ome, ace",
    filterUrl: 'https://videos.sproutvideo.com/embed/449ddfb71f1ce6cecd/a1ecbacd264fbf0b'
  },
  {
    title: "Syllable Letters",
    description: "Find words with the following syllable letters:",
    type: FilterComponentTypes.textarea,
    name: "sylLetters",
    currentVal: [],
    visible: false,
    example: "For example: tween, i, op",
    filterUrl: 'https://videos.sproutvideo.com/embed/4490dabd1e10eacdcd/73b00b663135751a'
  },
  {
    title: 'Initial Blends',
    description: 'Find words that contain any of the following initial blends:',
    type: FilterComponentTypes.textarea,
    name: 'initialblendArray',
    currentVal: [],
    visible: false,
    example: "For example: cr, pl, st",
    filterUrl: 'https://videos.sproutvideo.com/embed/119ddfb71f1ce7ce98/a402f72cc606863d'
  },
  {
    title: 'Ending Blends',
    description: 'Find words that contain any of the following ending blends:',
    type: FilterComponentTypes.textarea,
    name: 'endingblendArray',
    currentVal: [],
    visible: false,
    example: "For example: st, Id, nt",
    filterUrl: 'https://videos.sproutvideo.com/embed/069ddfb71f1ce7c58f/e00bed2ae944163f'
  },
  {
    title: 'CVC Patterns',
    description: 'Find words with any of the following consonant/vowel patterns.  Enter an \'E\' to include words with the silent \'e\' letter.  For example, the pattern for \'dim\' is \'CVC\' while pattern for \'dime\' is \'CVCE\'.',
    type: FilterComponentTypes.textarea,
    name: 'cvcPatterns',
    currentVal: [],
    visible: false,
    example: "For example: CVC, CCVC, CVCE",
    filterUrl: 'https://videos.sproutvideo.com/embed/ac9ddfb71f1ce7c725/4880dbfa1fd6b236'
  },
  {
    title: 'Suffixes',
    description: 'Find words with the following suffixes:',
    type: FilterComponentTypes.textarea,
    name: 'suffixes',
    currentVal: [],
    visible: false,
    example: "For example: tion, ness, ing",
    filterUrl: 'https://videos.sproutvideo.com/embed/ac9ddfb71f1ce1c125/cd31a718ac59726b'
  },
  {
    title: 'Prefixes',
    description: 'Find words with the following prefixes:',
    type: FilterComponentTypes.textarea,
    name: 'prefixes',
    currentVal: [],
    visible: false,
    example: "For example: re, un, bi",
    filterUrl: 'https://videos.sproutvideo.com/embed/a79ddfb71f1ce6c12e/1f97650b4c88e3da'
  },
  {
    title: 'Sound/Letter Pairings',
    description: 'Find specific sound/letter pairings by selecting the letters that you are looking for and then the specific sounds based on the example words given.  You can search for sound/letter pairings that occur anywhere in the word.',
    type: FilterComponentTypes.pairings,
    name: 'soundPairing',
    currentVal: [],
    filters: [
      {
        title: 'First Pairings',
        description: 'Find specific sound/letter pairings by selecting the letters that you are looking for and then the specific sounds based on the example words given.  You can search for sound/letter pairings that occur at the beginning of a word.',
        type: FilterComponentTypes.pairings,
        name: 'firstPairing',
        currentVal: [],
        visible: true
      },

      {
        title: 'Last Pairings',
        description: 'Find specific sound/letter pairings by selecting the letters that you are looking for and then the specific sounds based on the example words given.  You can search for sound/letter pairings that occur at the end of a word.',
        type: FilterComponentTypes.pairings,
        name: 'lastPairing',
        currentVal: [],
        visible: false
      },
      {
        title: 'Anywhere Pairings',
        description: 'Find specific sound/letter combinations by selecting a letter that you are looking for and then selecting from the corresponding sound options:',
        type: FilterComponentTypes.pairings,
        name: 'basicPairing',
        currentVal: [],
        visible: false
      }
    ],
    visible: false,
    filterUrl: 'https://videos.sproutvideo.com/embed/799ddfb71f1ce1c0f0/96f37f19ec28da89'
  },
  {
    title: 'Syllable Types',
    description: 'Find words with the following syllable types:',
    type: FilterComponentTypes.quickCheckbox,
    name: 'sylTypes',
    sylNum: 6,
    currentVal: [],
    visible: false,
    multipleCategories: true,
    options: [
      { description: "Word Must Contain: ", name: "wordMustContain", sylNum : 1, options: [
        { name: 'R-Controlled', value: 'R-Controlled'},
        {name: 'Vowel Team', value: 'Vowel Team'},
        {name: 'Magic E', value: 'Magic E'},
        {name: 'Consonant-le', value: 'Consonant-le'},
        {name: 'Open', value: 'Open'},
        {name: 'Closed', value: 'Closed'}
        ]
      },
        { description: "First Syllable: ", name: "firstSylType", sylNum : 1, options: [
        { name: 'R-Controlled', value: 'R-Controlled'},
        {name: 'Vowel Team', value: 'Vowel Team'},
        {name: 'Magic E', value: 'Magic E'},
        {name: 'Consonant-le', value: 'Consonant-le'},
        {name: 'Open', value: 'Open'},
        {name: 'Closed', value: 'Closed'}
        ]
      },
      { description: "Second Syllable: ", name: "secondSylType", sylNum : 2, options: [
        { name: 'R-Controlled', value: 'R-Controlled'},
        {name: 'Vowel Team', value: 'Vowel Team'},
        {name: 'Magic E', value: 'Magic E'},
        {name: 'Consonant-le', value: 'Consonant-le'},
        {name: 'Open', value: 'Open'},
        {name: 'Closed', value: 'Closed'}
        ]
      },
      { description: "Third Syllable: ", name: "thirdSylType", sylNum : 3, options: [
        { name: 'R-Controlled', value: 'R-Controlled'},
        {name: 'Vowel Team', value: 'Vowel Team'},
        {name: 'Magic E', value: 'Magic E'},
        {name: 'Consonant-le', value: 'Consonant-le'},
        {name: 'Open', value: 'Open'},
        {name: 'Closed', value: 'Closed'}
        ]
      },
      { description: "Fourth Syllable: ", name: "fourthSylType", sylNum : 4, options: [
        { name: 'R-Controlled', value: 'R-Controlled'},
        {name: 'Vowel Team', value: 'Vowel Team'},
        {name: 'Magic E', value: 'Magic E'},
        {name: 'Consonant-le', value: 'Consonant-le'},
        {name: 'Open', value: 'Open'},
        {name: 'Closed', value: 'Closed'}
        ]
      },
      { description: "Fifth Syllable: ", name: "fifthSylType", sylNum : 5, options: [
        { name: 'R-Controlled', value: 'R-Controlled'},
        {name: 'Vowel Team', value: 'Vowel Team'},
        {name: 'Magic E', value: 'Magic E'},
        {name: 'Consonant-le', value: 'Consonant-le'},
        {name: 'Open', value: 'Open'},
        {name: 'Closed', value: 'Closed'}
        ]
      },
      { description: "Sixth Syllable: ", name: "sixthSylType", sylNum : 6, options: [
        { name: 'R-Controlled', value: 'R-Controlled'},
        {name: 'Vowel Team', value: 'Vowel Team'},
        {name: 'Magic E', value: 'Magic E'},
        {name: 'Consonant-le', value: 'Consonant-le'},
        {name: 'Open', value: 'Open'},
        {name: 'Closed', value: 'Closed'}
        ]
      }
    ],
    filterUrl: 'https://videos.sproutvideo.com/embed/069ddfb71f1ce1c38f/c04fe5dbfe528696'
  },
  {
    title: 'Exact Tiles',
    description: 'Find words comprised only of the following tiles.  Click on the three dots on a letter tile to further limit the search to specific letter/sound combinations:',
    type: FilterComponentTypes.tiles,
    name: 'exactTiles',
    currentVal: [],
    visible: true,
    required: false,
    filterUrl: 'https://videos.sproutvideo.com/embed/4d9ddfb71f1ce7c2c4/4693b076c0de64bb'
  },
];
