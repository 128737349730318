import { Store } from '@ngrx/store';
import {Component, OnDestroy, EventEmitter, Output, OnInit, Inject, Input, OnChanges, SimpleChanges, ViewChild, ElementRef} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {FilterTypes, types} from '../../../../models/filter-types';
import {WordsService} from '../../../services/words.service';
import {filter, last, switchMap, take, takeUntil} from 'rxjs/operators';
import {WorkbooksService} from '../../../services/workbooks.service';
import {ActivatedRoute, Router} from '@angular/router';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { INTERCOM_ARTICLE_IDS, WORD_AUDIO_DIR } from '../../../../environments/environment';
import {CourseWorkbooksService} from '../../../services/course-workbooks.service';
import {UserService} from '../../../services/user.service';
import {CourseService} from '../../../services/courses.service';
import { ListenerService } from '../../../services/listener.service';
import { PlanService } from '../../../services/plan.service';
import { getSetCourseData, selectGetMyLibraryWorkbooks, selectGetPostResponseByWorkbookId, selectGetWorkbookFiles,
  selectGetWorkbookPassages, selectGetWorkbookPhrases, selectGetWorkbookSentences, selectGetPostResponse, selectGetAllCourseWorkbook, selectAllCourseWorkbookSuccess } from '../../../store';
import { CreateWorkbook, CreateWorkbookSuccess, GetWorkbook, UpdateWorkbook, UpdateWorkbookSuccess } from '../../../store/workbook/workbook.actions';
import { ClearCourseWorkbooks, CreateCourseWorkbookSuccess, GetAllCourseWorkbooks, GetCourseWorkbook, UpdateByWorkbook } from '../../../store/course-workbook/course-workbook.actions';
import { ClearWords, PostWords } from '../../../store/words/words.actions';
import { AsyncPipe } from '@angular/common';
import { ClearActivityInitData } from '../../../store/activity/activity.actions';
import { ClearWorkbookActivity } from '../../../store/workbook-activity/workbook-activity.actions';
import { ClearLessonStep } from '../../../store/edit-lesson/edit-lesson.actions';
import { ChatService } from '../../../services/chat.service';
import { CreateFeedback } from '../../../store/feedback/feedback.actions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { jsonrepair } from 'jsonrepair';

interface ChatMessage {
  from: 'user' | 'ai' | 'system';
  text?: string;
  time: Date;
  rawMessage?: any;
}

@Component({
  selector: 'app-workbook-builder-words',
  templateUrl: './html/words.html',
  styleUrls: [
    '../../../../assets/css/main.css',
    '../../../../assets/scss/fontawesome.scss',
    '../../../../assets/scss/brands.scss',
    '../../../../assets/scss/regular.scss',
    '../../../../assets/scss/solid.scss',
    './workbook-builder.scss'

  ]
})


export class WorkbookBuilderWordsComponent implements OnInit, OnDestroy {
  @Input() workbookNameObservable = new Observable();
  @Input() workbookDescriptionObservable = new Observable();
  @Input() workbook: any;
  @Input() newWorkbook = false;
  @Input() isFromLibrary = false;
  @Input() workbookId: string;
  @Input() changeNameModalClosed = false;
  @Input() originalWorkbookState: any;
  @Output() updatedWorkbook: EventEmitter<any> = new EventEmitter<any>();
  @Output() requestScrollToBottom = new EventEmitter<void>();
  public userKnowsWords;
  public filters: any[] = FilterTypes;
  public query: any = {};
  public words: any[] = [];

  public wordsSubject: any = new BehaviorSubject<any>(this.words);

  public words$ = this.wordsSubject.asObservable();
  public name: string;
  public workbooks: any;
  public bottomSheetOpen: boolean;
  public bottomSheetRef: any;
  public selectedWords: any = [];
  public filterSelection: string;
  @Output() syllableCount;
  faSpinner = faSpinner;
  isSearching: boolean;
  audio = new Audio();
  public userId: string;
  public plan: string;
  selectedPlan: any;
  currentCourse: any;
  public courseWorkbooks: any;
  private unsubscribe$: Subject<void> = new Subject();
  private hasPhrases = false;
  private hasSentences = false;
  private hasPassages = false;
  private hasFiles = false;

  // NOTE: This has chat values
  messages: ChatMessage[] = [];
  newMessage: string = '';
  systemMessage: string = `Describe your wordlist’s characteristics below using a complete sentence.`;
  waitingForResponse: boolean = false;
  threadId: string = '';

  // Undo and redo based on workbook filters
  private undoStack: any[] = [];
  private redoStack: any[] = [];

  // Hook into the change messages component to scroll down called #chatScroll
  @ViewChild('chatScroll') messagesBox: ElementRef;

  constructor(
    private wordsService: WordsService,
    private workbookService: WorkbooksService,
    private route: ActivatedRoute,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    private courseWorkbooksService: CourseWorkbooksService,
    private courseService: CourseService,
    private userService: UserService,
    private listenerService: ListenerService,
    private planService: PlanService,
    private chatService: ChatService,
    private dialog: MatDialog,
    private store: Store,
    private async: AsyncPipe
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._bottomSheet.dismiss();
    this.store.dispatch(new ClearWords({
      id: this.workbookId
    }));
  }

  ngOnInit() {
    this.messages = [
      { from: 'ai', text: 'Hi! Please tell me about the wordlist that you would like to create.', time: new Date() },
      // { from: 'user', text: `Hi. I want you to create a workbook that has words that end with "ing".`, time: new Date() },
      // { from: 'ai', text: `I have created the workbook with words ending in "ing".`, time: new Date() },
      // { from: 'user', text: 'Can you update the title of the workbook to be called "AI Book 4?"', time: new Date() },
      // { from: 'ai', text: 'Sure, I have updated the workbook title to be called "AI Book 4."', time: new Date() },
      // { from: 'user', text: 'I want you to add a description to this workbook as well. Can you do that?', time: new Date() },
      // { from: 'ai', text: 'Okay. I added a description based on the contents of the workbook.', time: new Date() },
    ];

    this.listenerService.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plan) => {
      this.plan = plan;
    });
    this.filters = [...[], ...this.filters];
    this.filters.forEach((filter) => {
      filter.visible = false;
    });

    this.filterFilters = this.filterFilters.bind(this);
    const idIndex = this.route.url['value'].length - 1;
    this.workbookId = this.route.url['value'][idIndex].path;
    this.userId = JSON.parse(localStorage.getItem('profile')).user_metadata.uid;

    // this.workbookService
    // .getAll()
    // .subscribe((workbooks) => {
    //   this.workbooks = workbooks;
    // });
    // this.store.dispatch(new PostWords({
    //   id: this.workbookId,
    //   filter: this.workbook.filters
    // }));
    this.store.select(selectGetMyLibraryWorkbooks)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbooks => this.workbooks = JSON.parse(JSON.stringify(workbooks)));

    this.store.select(getSetCourseData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(currentCourse => {
        this.currentCourse = JSON.parse(JSON.stringify(currentCourse));
      });

      this.store.select(selectGetAllCourseWorkbook)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(courseWorkbooks => {
        this.courseWorkbooks = [...(courseWorkbooks || [])];
      });

    Object.keys(this.workbook.filters).forEach(key => {
      const filterIndex = this.filters.findIndex(filter => filter.name === key);
      if (filterIndex >= 0) {
        this.filters[filterIndex].currentVal = this.workbook.filters[key];
        if (!this.filters[filterIndex].required) {
          this.filters[filterIndex].visible = true;
        }
      }
    });
    this.captureState();
    this.workbookNameObservable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(name => {
        this.workbook.name = name;
      });

    this.workbookDescriptionObservable
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(desc => {
        this.workbook.desc = desc;
      });
      // this.search();
      this.getSelectedPlan();
    // this.search();

    this.store.select(selectGetWorkbookPhrases)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookPhrases => {
        this.hasPhrases = !!(workbookPhrases?.length);
      });

    this.store.select(selectGetWorkbookSentences)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookSentences => {
        this.hasSentences = !!(workbookSentences?.length);
      });

      this.store.select(selectGetWorkbookPassages)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookPassages => {
        this.hasPassages = !!(workbookPassages?.length);
      });

      this.store.select(selectGetWorkbookFiles)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(workbookFiles => {
        this.hasFiles = !!(workbookFiles?.length);
      });

    this.buildWordList()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((words: any) => {
      this.wordsSubject.next(words);

      console.log("The list has been built.");

      // this code is a patch for an issue of the sylnumber not being updated for child components
      // setTimeout(() => {
      //     const nSyllable = this.filters.find(filter => filter.name === 'nSyllables');
      //     this.updateQuery({nSyllables: nSyllable.currentVal + 3})
      //   },500)
  
      
      //   setTimeout(() => {
      //     const nSyllable = this.filters.find(filter => filter.name === 'nSyllables');
      //     this.updateQuery({nSyllables: nSyllable.currentVal})
      //   },1000)
    });
  }

  updateQuery(value: any) {
    const queryName = Object.keys(value)[0];
    if (value[queryName] === null) {
      return (delete this.workbook.filters[queryName]);
    }
    if (value.nSyllables) {
      this.syllableCount = (value.nSyllables.lte - 1);
    }

    console.log(value);
    // this.workbook.filters = {...this.workbook.filters, ...value};
    this.updateWorkbookFilters(value);
  }

  search(exit: boolean = false) {
     for (let i in this.workbook.filters) {
       if (this.workbook.filters.hasOwnProperty(i)) {
        if ((this.workbook.filters[i][0] === '' && this.workbook.filters[i].length === 1) || this.workbook.filters[i].length === 0) {
          delete this.workbook.filters[i];
        }
       }
     }

    this.store.dispatch(new PostWords({
      id: this.workbookId,
      filter: this.workbook.filters
    }));
  }

  buildWordList() {
    return new Observable(observer => {
      this.isSearching = true;
      this.store.select(selectGetPostResponseByWorkbookId, { id: this.workbookId })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((words: any[]) => {
          if (!words) {
            this.words = [];
            return;
          }

          console.log("We building a new list after a search has happened");
          // reorder words by workbook preview
          let reorderedWords = [...JSON.parse(JSON.stringify(words))];
          // if (this.workbook && this.workbook.preview) {
          //   for (let i = 0; i < this.workbook.preview.length; i++) {
          //     const index = reorderedWords.findIndex(w => w.wordid === this.workbook.preview[i].wordid);
          //     if (index > -1) {
          //       reorderedWords = this.moveWords(reorderedWords, index, i);
          //     }
          //   }
          // }

          const exactWordsFilter = this.workbook.filters.exactWords;
          this.isSearching = false;

          // This should apply to all new workbook
          if (this.workbook.wordOrder && this.workbook.wordOrder.length > 0) {
            // let orderedWords = this.reorganizeWords(this.workbook.wordOrder, reorderedWords);
            let orderedWords = null;

            const newWordSet = new Set(reorderedWords.map(word => word.word));
            const commonWords = this.workbook.wordOrder.filter(word => newWordSet.has(word.word));

            if(commonWords.length === 0) {
              // Reset the wordOrder if there are no common words
              this.workbook.wordOrder = null;

              // This may need to do the functionality below that starts with "if (exactWordsFilter && exactWordsFilter.length > 0) {"
              orderedWords = reorderedWords;

              observer.next(
                this.plan === 'Demo'
                  ? orderedWords.slice(0, 7)
                  : orderedWords
              );
            } else {
              const commonWordsSet = new Set(commonWords.map(word => word.word));
              const newWordsMap = new Map(reorderedWords.map(word => [word.word, word]));
              const commonWordsData = commonWords.map(word => newWordsMap.get(word.word));
              const remainingNewWords = reorderedWords.filter(word => !commonWordsSet.has(word.word));
              
              orderedWords = [...commonWordsData, ...remainingNewWords];
  
              observer.next(
                this.plan === 'Demo'
                  ? orderedWords.slice(0, 7)
                  : orderedWords
              );
            }
          } else {
            if (exactWordsFilter && exactWordsFilter.length > 0) {
              let searchedWords: any[] = [];
              exactWordsFilter.forEach(word => {
                const searchedWord = JSON.parse(JSON.stringify(reorderedWords)).filter(w => w.word === word);
                if (searchedWord.length > 0) {
                  searchedWords = searchedWords.concat(searchedWord);
                }
              });

              console.log(searchedWords);
              searchedWords = this.plan === 'Demo' ? searchedWords.slice(0, 7) : searchedWords;
              // searchedWords = this.plan !== 'Staff' ? searchedWords.filter(word => word.planType.toLowerCase() === 'staff') : searchedWords;
              observer.next(searchedWords);
            } else {
              observer.next(
                this.plan === 'Demo'
                  ? reorderedWords.slice(0, 7)
                  : reorderedWords
              );
            }
          }
        });
    });
  }

  generateTiles() {
    let words: any = this.async.transform(this.words$);
    return new Observable(observer => {
      if (this.workbook.filters.exactSounds) {
        observer.next(this.workbook.filters.exactSounds);
      } else {
        const userId = JSON.parse(localStorage.profile).user_metadata.uid;
        this.wordsService
          .getTilesByWordIds(userId, words.slice(0, 30).map(word => word.wordid))
          .pipe(
            take(1)
          )
          .subscribe(
            (tiles: any[]) => {
              tiles = [...new Set(tiles.flat())];
              observer.next(tiles);
            },
            error => {
              throw error;
            }
          );
      }
    });
  }

  openExamplePrompts() {
    (window as any).Intercom('showArticle', INTERCOM_ARTICLE_IDS.examplePrompts);
  }

  openFeedbackModel() {
    const dialogRef = this.dialog.open(FeedbackFormComponent, {
      panelClass: 'feedback-form-panel',
    });
    dialogRef.afterClosed().subscribe((result: any) => {

      if(result && result.send) {
        let feedback = this.createFeedbackSubmission(result.userMessage, '');
        this.store.dispatch(new CreateFeedback({
          type: 'chat',
          ...feedback
        }));
      }
      // this.changeNameModalClosed = true;
      // this.isLoading = true;
      // if (newWorkbook) {
      //   this.workbook = newWorkbook;
      //   this.words.save(false, this.isFromLibrary);
      //   this.originalWorkbookState = JSON.stringify(this.workbook);
      // } else {
      //   this.router.navigate([`my-curriculum`]);
      // }
    });
  }

  createFeedbackSubmission(userMessage: string, errorMessage: string) {
    let timestamp = this.messages[this.messages.length - 1].time; // Last message's timestamp
    let userEmail = JSON.parse(localStorage.getItem('profile')).email;
    let userId = JSON.parse(localStorage.getItem('profile')).user_metadata.uid;
    
    // Get all messages in a string format
    let allMessages = '';
    if(this.messages.length > 1) {
      this.messages.forEach(message => {
        allMessages += `${message.from}: ${message.text}${message.rawMessage ? ` | ${message.rawMessage}` : ''}\n`;
      });
    }

    let currentWordList = this.async.transform(this.words$) as any[];
    let words = currentWordList.map(word => word.displayWord).join(', ');

    let lastMessage = this.messages[this.messages.length - 1];
    let filters;

    if(userMessage !== '') {
      filters = this.filters.filter(filter => filter.visible).map(filter => {
        return {
          name: filter.name,
          value: filter.currentVal
        }
      });
    }

    if(errorMessage !== '') {
      filters = this.undoStack[this.undoStack.length - 1].filtersList.filter(filter => filter.visisble).map(filter => {
        return {
          name: filter.name,
          value: filter.currentVal
        }
      });
    }

    filters = JSON.stringify(filters);

    return {
      timestamp,
      userEmail,
      userId,
      userMessage,
      errorMessage,
      allMessages,
      words,
      filters
    }
  }

  /**
 * Captures the current state of the workbook
 * and pushes it onto the undo stack.
 */
  private captureState() {
    const workbookSnapshot = JSON.parse(JSON.stringify(this.workbook)); // Deep copy
    const filtersSnapshot = JSON.parse(JSON.stringify(this.filters)); // Deep copy
    const workbookFilters = workbookSnapshot.filters;
    const filters = filtersSnapshot;
    const filtersState = { filters: workbookFilters, filtersList: filters };
    console.log(filtersState);
    this.undoStack.push(filtersState);
    this.redoStack = []; // Clear redo stack on new changes
  }  


  /**
   * Undo last change: Revert to the previous state.
   */
  undo() {
    // NOTE: This prevents the user from undoing the initial state (we will always have a copy of the initial state)
    if (this.undoStack.length > 1) {
      const currentWorkbookState = JSON.parse(JSON.stringify(this.workbook));
      const currentFiltersState = JSON.parse(JSON.stringify(this.filters));
      const currentWorkbookFilters = currentWorkbookState.filters;
      const currentFilters = currentFiltersState;
      const combinedState = { filters: currentWorkbookFilters, filtersList: currentFilters };
      console.log(combinedState);
      this.redoStack.push(combinedState); // Save current state before undoing

      const previousState = this.undoStack.pop();

      console.log(previousState)

      this.workbook.filters = previousState.filters; // Restore previous state
      this.filters = previousState.filtersList;
      // this.updateUI();
    }
  }

  /**
   * Redo last undone change.
   */
  redo() {
    if (this.redoStack.length > 0) {
      const currentWorkbookState = JSON.parse(JSON.stringify(this.workbook));
      const currentFiltersState = JSON.parse(JSON.stringify(this.filters));
      const currentWorkbookFilters = currentWorkbookState.filters;
      const currentFilters = currentFiltersState;
      const combinedState = { filters: currentWorkbookFilters, filtersList: currentFilters };
      console.log(combinedState);
      this.undoStack.push(combinedState); // Save current state before redoing

      const previousState = this.redoStack.pop();
      console.log(previousState);
      this.workbook.filters = previousState.filters; // Restore redo state
      this.filters = previousState.filtersList;
      // this.updateUI();
    }
  }

  /**
 * Call this function whenever workbook changes.
 * Example: When a filter updates, workbook title changes, etc.
 */
  updateWorkbookFilters(newWorkbookFilters: any) {
    this.captureState(); // Save previous state before modifying
    this.workbook.filters = { ...this.workbook.filters, ...newWorkbookFilters }; // Apply changes
    // this.updateUI();
  }

  sendMessage() {
    if (this.newMessage === '') {
      return;
    }
  
    // TODO: Check message for profanity
    const message = this.newMessage.trim();
  
    this.messages.push({
      from: 'user',
      text: message,
      time: new Date()
    });
  
    this.newMessage = '';
    this.waitingForResponse = true;
    this.scrollChatDown();
  
    this.chatService.sendMessage(this.threadId, message)
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          console.log(response);
  
          if (this.threadId === '') {
            this.threadId = response.thread_id;
          }
  
          let messages = response.messages;
          let lastMessage = messages[messages.length - 1];
          let content = lastMessage.content;
          let createdAt = lastMessage?.created_at;
          // NOTE: All content fixing should go here

        // Check if there are quotes at beginning and end of content and remove them
  
          if (content[0] === '"') {
            content = content.slice(1);
          }
          if (content[content.length - 1] === '"') {
            content = content.slice(0, content.length - 1);
          }
          
          // Parse the content and remove the ```json
          content = content.replace('```json', '').trim();
          if (content.slice(content.length - 3) === '```') {
            content = content.slice(0, content.length - 3);
          }

          content = content.trim();
          console.log(content);

          try {
            let json = this.tryParseAndRepair(content);

            let entry: ChatMessage = {
              from: 'ai',
              time: createdAt,
              rawMessage: lastMessage.content
            }

            this.messages.push(entry);

            // It was successfully parsed
            this.messages[this.messages.length - 1].text = json.message;
            
            this.waitingForResponse = false;

            this.updateFilters({
              action: json.action,
              filters: json.filters,
            });

            this.scrollChatDown();
          } catch (error) {
            this.handleError(new Error('The response is in the incorrect format. It should be in a JSON format.'));
            throw new Error('The response is in the incorrect format. It should be in a JSON format.');
          }

        },
        error: (err) => {
          this.handleError(err);
        }
      });
  }

  tryParseAndRepair(input: string): any {
    try {
        // Try direct parse
        return JSON.parse(input);
    } catch {
        try {
            // Clean up markdown-style blocks
            input = input
                .replace(/^```json/, '')
                .replace(/^```/, '')
                .replace(/```$/, '');

            // Extract most JSON-like block
            const match = input.match(/{[\s\S]*}/);
            if (!match) throw new Error("No JSON block found");

            let cleaned = match[0];

            // Normalize smart quotes
            cleaned = cleaned
                .replace(/[\u2018\u2019]/g, "'")  // curly single quotes
                .replace(/[\u201C\u201D]/g, '"')  // curly double quotes
                .replace(/“|”/g, '"');

            // Fix bad newlines in JSON
            cleaned = cleaned.replace(/\\n/g, "\\n");

            // Remove extra trailing commas (common with LLMs)
            cleaned = cleaned.replace(/,(\s*[}\]])/g, '$1');

            // Ensure keys are properly quoted
            cleaned = cleaned.replace(/([{,]\s*)([a-zA-Z0-9_]+)(\s*:)/g, '$1"$2"$3');

            // 🔧 Fix unescaped inner quotes (common with message field)
            cleaned = cleaned.replace(/"(.*?)"\s*:\s*"(.*?)"(?=\s*,|\s*})/g, (match, key, value) => {
                const fixedValue = value.replace(/"/g, '\\"');
                return `"${key}": "${fixedValue}"`;
            });

            // Optional: Fix improperly closed arrays/objects if needed
            // You can add custom logic here to auto-close open brackets/braces, etc.
            
            // Attempt to repair
            const repaired = jsonrepair(cleaned);

            // Final parse
            return JSON.parse(repaired);
        } catch (err) {
            console.error("❌ Failed to repair/parse JSON:", err);
            throw err;
        }
    }
}
  
  handleError(error: any) {
    console.error('Error occurred:', error);

    let feedback = this.createFeedbackSubmission('', error.message);

    this.store.dispatch(new CreateFeedback({
      type: 'chat',
      ...feedback
    }));

    // // Optionally, notify the user that an error occurred
    // this.messages.push({
    //   from: 'system',
    //   text: 'There was an error processing your request. Please try again or report the issue.',
    //   time: new Date()
    // });

    this.waitingForResponse = false;
  }

  handleEnter(event: KeyboardEvent) {
    if(this.waitingForResponse) {
      return;
    }

    if(event.key === 'Enter') {
      // Stops the textarea from jumping to a newline
      event.preventDefault();
      this.sendMessage();
    }
  }

  scrollChatDown(delay = 100) {
    setTimeout(() => {
      // Add smooth scolling
      this.messagesBox.nativeElement.scrollTo({
        top: this.messagesBox.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
    }, delay);
  }

  public updateFilters(data: any) {
    if(!data) {
      return;
    }
    
    const action = data.action;

    if(action === 'update_workbook_title' || action === 'update_workbook_description') {

      if(action === 'update_workbook_title') {
        // TODO: Implement this
        // this.workbook.name = data.title;
      }

      if(action === 'update_workbook_description') {
        // TODO: Implement this
        // this.workbook.desc = data.description;
      }

    } else if (action === 'filter_information') {

      // TODO: Implement this eventually
      // We want this to come through as a chat message OR in a thing called 'filter_types' that will be a list of filters that the AI model can use
      // We can then created custom components that show up in the chat. What would be cool is if these custom components could be hooked up to the 
      // current filters to control the data in the workbook below and be maintained in the chat

    } else if (action === 'undo') {
      const undoStackLength = this.undoStack.length;
      this.undo();

      if(undoStackLength > 1) {
        this.search();
      } else {
        setTimeout(() => {
          this.messages.push({
            from: 'ai',
            text: 'There are no more actions to undo.',
            time: new Date()
          })
        }, 500);
      }
    } else if (action === 'redo') {
      const redoStackLength = this.redoStack.length;
      this.redo();

      if(redoStackLength > 0) {
        this.search();
      } else {
        setTimeout(() => {
          this.messages.push({
            from: 'ai',
            text: 'There are no more actions to redo.',
            time: new Date()
          })
        });
      }
    // } else if (action === 'remove_filter') {
    //   const filterName = data?.filter;

    //   if(!filterName) {
    //     return;
    //   }

    //   // Check if the filter exists
    //   const filterIndex = this.filters.findIndex(filter => filter.name === filterName);

    //   if(filterIndex === -1) {
    //     return;
    //   }

    //   // Get the value for the filter from the AI model and remove it from the filter
    //   // If it is the last value (in the case of an array), remove the filter

    } else if (action === "remove_filter" || action === 'update_filter' || action === 'create_workbook' || action === 'update_workbook') {
      // NOTE: For now, create_workbook and update_workbook do the same thing
      const filters = data?.filters;

      if(!filters) {
        return;
      }

      // Check if these filters exists
      const filterNames = Object.keys(filters);
      const validFilters = this.filters.filter(filter => filterNames.includes(filter.name));
      const filterInformation = FilterTypes;

      console.log(validFilters);

      if(validFilters.length === 0) {
        return;
      }

      // Save Current State
      this.captureState();

      // TODO: Add check to see if values from AI model are valid

      // If it is an object, validate the values
      // If it is an array, make sure it is formatted as an array
      // If it is a number, make sure it is a number
      // If it is supposed to be a string, make sure it is

      // TODO: Make it so that the AI model tells you if this needs to happen. We are assuming that it will just keep adding or updating filters for now
      // this.closeAllOptionFilters();

      let valuesToAdd = {};

      // Update the filters
      validFilters.forEach(filter => {
        const filterName = filter.name;
        let filterValue = filters[filterName];

        // Should just open this filter without doing anything else
        if(filterName === 'exactTiles') {
          console.log('exactTiles', filterValue);
          const index = this.filters.findIndex(f => f.name === filterName);
          const fil = this.filters.splice(index, 1)[0];
          delete fil.currentVal;
          fil.visible = true;
          this.filters.push(JSON.parse(JSON.stringify(fil)));
          this.workbook.filters = { ...this.workbook.filters, exactTiles: [] };
          return;
        }

        // Check if filterValue is not an empty array, object, or string
        if (filterValue === null || filterValue === undefined || filterValue === '' || (Array.isArray(filterValue) && filterValue.length === 0) || (typeof filterValue === 'object' && Object.keys(filterValue).length === 0)) {
          // Close filter if it is open
          this.closeFilter(filterName);
          return;
        }

        
        
        const index = this.filters.findIndex(f => f.name === filterName);
        const fil = this.filters.splice(index, 1)[0];
        // delete fil.currentVal;

        if(filterName === 'exactWords') {
          this.enableNonsense();
        }

        // console.log(filterName);
        // console.log(fil);
        let type = this.findFilterType(filterName);

        // console.log(type);
        // console.log('value', filterValue);

        // Make sure value is correct
        if(type === 'object') {
          // Check if the value is an object
          if(Array.isArray(filterValue)) {
            if(filterName === "sylTypes") {
              // Grab all array entries and put them into an object (the entries are objects)
              let obj = {};
              filterValue.forEach(item => {
                obj = {
                  ...obj,
                  ...item
                }
              });

              filterValue = obj;
              fil.currentVal = filterValue;
            }
          } else if(typeof filterValue === 'object') {

            console.log(fil);

            if (filterName === 'nSyllables' || filterName === 'nLetters' || filterName === 'nSounds') {
              // Check if it contains gte and lte. If one of the values is missing, set a default value of the max or min
              if (filterValue.gte === undefined) {
                if (filterValue.lte) {
                  let defaultMin = fil.currentVal.gte;
                  let newMin = Math.min(defaultMin, filterValue.lte)
                  let min = newMin < defaultMin ? defaultMin : newMin;
                  filterValue.gte = newMin;
                } else {
                  filterValue.gte = fil.currentVal.gte;
                }
              } else {
                let min = Math.max(0, filterValue.gte);
                filterValue.gte = min;
              }

              if (filterValue.lte === undefined) {
                if (filterValue.gte) {
                  let defaultMax = fil.maxNum;
                  if (filterName === 'nLetters') {
                    defaultMax = 20;
                  }
                  let newMax = Math.max(defaultMax, filterValue.gte);
                  let max = newMax > defaultMax ? defaultMax : newMax;
                  filterValue.lte = max;
                } else {
                  let max = fil.maxNum;
                  if (filterName === 'nLetters') {
                    max = 20;
                  }
                  filterValue.lte = max;
                }
              } else {
                if (filterName === 'nLetters') {
                  filterValue.lte = Math.min(20, filterValue.lte);
                }
              }
            }

            fil.currentVal = filterValue;
          } else {
            // NOTE: This should not happen
            // fil.currentVal = defaultValue;
          }
        } else if(type === 'array') {
          // Check if the value is an array
          if(Array.isArray(filterValue)) {

            if(filterName === 'exactWords') {
              // Set all words to lower case
              filterValue = filterValue.map(word => word.toLowerCase());
            }

            fil.currentVal = filterValue;
          } else {
            if(typeof filterValue === 'string') {
              let arrayValue = filterValue.split(',');
              filterValue = arrayValue;

              if(filterName === 'exactWords') {
                // Set all words to lower case
                filterValue = filterValue.map(word => word.toLowerCase());
              }

              console.log(filterValue);
              console.log(arrayValue)
              fil.currentVal = arrayValue;
            }
          }
        } else if(type === 'number') {
          // Check if the value is a number
          if(typeof filterValue === 'number') {
            fil.currentVal = filterValue;
          } else {
            let toNumber = Number(filterValue);
            filterValue = toNumber;
            fil.currentVal = toNumber;
          }
        } else if(type === 'string') {
          // Check if the value is a string
          if(typeof filterValue === 'string') {
            if(filterName === 'wordEndsWith' || filterName === 'anyGrapheme' || filterName === 'doesNotContainGrapheme' || filterName === 'firstGrapheme' || filterName === 'lastGrapheme') {
              let value = filterValue.split(',').map(item => item.trim());
              fil.currentVal = value;
              filterValue = value;
            } else {
              fil.currentVal = filterValue;
            }
          } else {
            let toString = String(filterValue);
            filterValue = toString;
            fil.currentVal = toString;
          }
        } else if(type === 'boolean') {
          // Check if the value is a boolean
          console.log(typeof filterValue);
          if(typeof filterValue === 'boolean') {
            fil.currentVal = filterValue;
          } else {
            let toBoolean = Boolean(filterValue);
            filterValue = toBoolean;
            fil.currentVal = toBoolean;
          }
        }
        
        fil.visible = true;
        this.filters.push(JSON.parse(JSON.stringify(fil)));

        // Value that will be added to the workbook
        valuesToAdd[filterName] = filterValue;
      });

      // Check to see if validsToAdd is empty
      if(Object.keys(valuesToAdd).length === 0) {
        return;
      }


      // Update this so it will find the current filter and update it in place instead of just appending it unless it doesn't exist
      this.workbook.filters = {...this.workbook.filters, ...valuesToAdd};
      // this.updateWorkbookFilters(valuesToAdd);

      // Run a search when the filters are updated
      this.search(); 
    }
  }

  enableNonsense() {
    const index = this.filters.findIndex(filter => filter.name === 'isNonsense');
    const nonsenseFilter = this.filters.splice(index, 1)[0];
    if (nonsenseFilter) {
      nonsenseFilter.visible = true;
      nonsenseFilter.currentVal = ['real', 'nonsense'];
      this.filters.push(JSON.parse(JSON.stringify(nonsenseFilter)));
      this.workbook.filters = { ...this.workbook.filters, isNonsense: ['real', 'nonsense'] };
    }
  }

  findFilterType(value: any): string {
    // Check if it is an object, array, string, or number
    return types[value];
  }

  closeAllOptionFilters() {
    this.workbook = JSON.parse(JSON.stringify(this.workbook));
    console.log(this.filters);
    for (let i in this.workbook.filters) {
      if (i !== 'isNonsense' && i !== 'isPhonetic') {
        delete this.workbook.filters[i];
      }
    }
    for (let i in this.filters) {
      let name = this.filters[i].name;
      if(name !== 'isNonsense' && name !== 'isPhonetic') {
        this.filters[i].visible = false;
      }
    }
    this.search();
    this.filterSelection = null;
  }

  // TODO: Add the code here to make sure the originalWorkbookState is updated to prevent a loop
  // setTimeout(() => {
  //   // Update original state after successful save
  //   this.originalWorkbookState = JSON.stringify(this.workbook);
  // }, 500);
  public save(exit: boolean, isLibrary: boolean, workbookId = null) {

    console.log("Save is called.");
    this.search();
    setTimeout(() => {
      this.closeBottomSheet();
      let words: any = this.async.transform(this.words$);
      // Update
      if ((workbookId == null && this.workbook._id) || (typeof workbookId === 'string' && workbookId.length > 0 && workbookId !== 'new')) {
        let updatedWorkbook = {
          name: this.workbook.name,
          owner: this.workbook.owner ? this.workbook.owner : this.userId,
          desc: this.workbook.desc,
          filters: this.workbook.filters,
          workbookId: this.workbook._id,
          hasPhrases: this.hasPhrases,
          hasSentences: this.hasSentences,
          hasPassages: this.hasPassages,
          hasFiles: this.hasFiles,
          courseWorkbookId: this.currentCourse?._id,
          tiles: null,
          wordOrder: null, // This shouild always been null unless an order is set
        };
        if (typeof workbookId === 'string' && workbookId.length > 0 && workbookId !== 'new') {
          updatedWorkbook.workbookId = workbookId;
        }
        updatedWorkbook["nonsense"] = false;
        const nonsenseWord = words.filter((word) => word.wordid >= 1000000);
        if (nonsenseWord && nonsenseWord.length > 0) {
          updatedWorkbook["nonsense"] = true;
        }
        updatedWorkbook["preview"] = words.slice(0, 50).map(word => ({word: word.word, wordid: word.wordid}));

        // Saves the order that the words were left in after rearranging them
        if (this.workbook.wordOrder && this.workbook.wordOrder.length > 0) {
          updatedWorkbook.wordOrder = this.workbook.wordOrder;
        }

        this.store.dispatch(new UpdateWorkbook({
          _id: this.workbook._id,
          data: updatedWorkbook,
        }));

        // updated workbook gets frozen after dispatching the action so we need to copy it
        let updatedWorkbookCopy = JSON.parse(JSON.stringify(updatedWorkbook));
        
        this.generateTiles()
          .pipe(take(1))
          .subscribe(tiles => {
            if (tiles) {
              updatedWorkbookCopy.tiles = tiles;
              this.updatedWorkbook.emit(updatedWorkbookCopy);

              this.store.dispatch(new UpdateWorkbook({
                _id: this.workbook._id,
                data: updatedWorkbookCopy,
              }));
              
              if (exit && isLibrary) {
                this.router.navigateByUrl(`/library;libraryType=myLibrary`);
              } else if (exit) {
                let courseWorkbook = this.courseWorkbooks.find(item => item.workbookId === this.workbook._id)
                if (courseWorkbook) {
                  courseWorkbook = JSON.parse(JSON.stringify(courseWorkbook));
                  courseWorkbook.workbook = updatedWorkbook;
                }

                this.store.dispatch(new UpdateByWorkbook({
                  _id: this.workbook._id,
                  ownerKey: this.userId,
                  courseWorkbook: updatedWorkbookCopy,
                  courseId: this.currentCourse?._id
                }));

                this.store.dispatch(new ClearActivityInitData());
                this.store.dispatch(new ClearWorkbookActivity());
                this.store.dispatch(new ClearLessonStep());
                // this.store.dispatch(new GetAllCourseWorkbooks(this.currentCourse?._id));

                setTimeout(() => {
                  this.router.navigate([`my-curriculum`]);
                }, 500);
              }
            }
          });
      } else {
        let maxOrder = 0;
        this.courseWorkbooks.map(({order}) => {
          if (order > maxOrder) {
            maxOrder = order;
          }
        });

        const newWorkbook: any = {
          name: this.workbook.name ? this.workbook.name : "Untitled Workbook",
          owner: this.workbook.owner ? this.workbook.owner : this.userId,
          desc: this.workbook.desc ? this.workbook.desc : "Enter a workbook description (optional)",
          filters: this.workbook.filters,
          concept: "new",
          order: maxOrder + 1
        };
        newWorkbook.nonsense = false;
        const nonsenseWord = words.filter((word) => word.wordid >= 1000000);
        if (nonsenseWord && nonsenseWord.length > 0) {
          newWorkbook.nonsense = true;
        }
        newWorkbook.preview = words.slice(0, 50).map(word => ({word: word.word, wordid: word.wordid}));

        let workbookTmp = null;
        this.generateTiles()
        .pipe(
        switchMap((tiles: any) => {
          newWorkbook.tiles = tiles;
          return this.workbookService.create(newWorkbook);
        }),
        switchMap((workbook: any) => {
          this.store.dispatch(new CreateWorkbookSuccess(workbook));
          workbookTmp = workbook;
          if (this.isFromLibrary) {
            return new Observable<any>(observer => observer.next({data: {workbookId: workbook._id}}));
          }
          const newCourseWorkbook = {
            courseId: this.currentCourse?._id,
            ownerKey: this.userId,
            workbookId : workbook._id
          };

          return this.courseWorkbooksService.create(newCourseWorkbook);
        })
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          if (exit && isLibrary) {
            this.router.navigate([`/library`, {libraryType: 'myLibrary'}]);
          } else if (exit) {
              setTimeout(() => {
                this.router.navigate([`my-curriculum`]);
              }, 300);

            this.store.dispatch(new GetAllCourseWorkbooks(this.currentCourse?._id));

          } else {
            if (!isLibrary) {
              this.store.dispatch(new CreateCourseWorkbookSuccess({
                ...workbookTmp,
                ...result.data
              }));
            }
            this.router.navigate([`/workbook-builder/${result.data.workbookId}`, {isFromLibrary: this.isFromLibrary}]);
          }
        });
      }
    }, 500)
    setTimeout(() => {
      // Update original state after successful save
      this.originalWorkbookState = JSON.stringify(this.workbook);
    }, 500);
  }

  filterFilters(filter: any) {
    const maxSyllables = this.workbook.filters.nSyllables
      ? this.workbook.filters.nSyllables.lte
      : FilterTypes.find(filterType => filterType.name === 'nSyllables').maxNum;

    // const sylNumPredicate = filter.sylNum
    //   ? filter.sylNum <= maxSyllables
    //   : true;

    return !filter.visible && !filter.required;
    // && sylNumPredicate;
  }

  openFilter(name: any) {
    const index = this.filters.findIndex(f => f.name === name);
    const filter = this.filters.splice(index, 1)[0];
    delete filter.currentVal;
    filter.visible = true;
    this.filters.push(JSON.parse(JSON.stringify(filter)));
    this.filterSelection = "";

    // Emit an event when the filter is opened to scroll to bottom
    setTimeout(() => {
      this.requestScrollToBottom.emit();
    }, 200);
  }

  closeFilter(filterName: any) {
    this.workbook = JSON.parse(JSON.stringify(this.workbook));
    if (filterName === "pairings") {
      delete this.workbook.filters['firstPairing'];
      delete this.workbook.filters['lastPairing'];
      delete this.workbook.filters['basicPairing'];
    }
    if (filterName === "sylTypes") {
      delete this.workbook.filters['wordMustContain'];
      delete this.workbook.filters['firstSylType'];
      delete this.workbook.filters['secondSylType'];
      delete this.workbook.filters['thirdSylType'];
      delete this.workbook.filters['fourthSylType'];
      delete this.workbook.filters['fifthSylType'];
      delete this.workbook.filters['sixthSylType'];
    }

    if (filterName === "exactWords") {
      delete this.workbook.filters['exactWords'];
      this.search();
    }
    const index = this.filters.findIndex(filter => filter.name === filterName);
    this.filters[index].visible = false;
    delete this.workbook.filters[`${filterName}`];
    this.filterSelection = null;
    // check for syllableType and soundLetterPairing

  }

  selectWord(wordIndex: number) {
    let words: any = this.async.transform(this.words$);
    words[wordIndex].selected = !words[wordIndex].selected;
    if (words[wordIndex].selected) {
      this.selectedWords.push(words[wordIndex]);
    } else {
      this.selectedWords = this.selectedWords.filter( word => {
        return word.word !== words[wordIndex].word;
      });
    }

    this.wordsSubject.next(words);
    this.openBottomSheet();
  }

  playAudio(wordIndex: number) {
    let words: any = this.async.transform(this.words$);
    this.audio.src = WORD_AUDIO_DIR + words[wordIndex].wordid + '_' + words[wordIndex].displayWord.toLowerCase() + '.mp3';
    this.audio.load();
    this.audio.play();
  }

  openBottomSheet() {
    let words: any = this.async.transform(this.words$);

    if (!this.bottomSheetOpen) {
      this.bottomSheetRef = this._bottomSheet.open(WordBottomSheetComponent,
        {
          hasBackdrop : false,
          disableClose: true
        }
      );
      this.bottomSheetOpen = true;
      this.bottomSheetRef.instance.deselectAllEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.deselectAll(words);
      });
      this.bottomSheetRef.instance.selectAllEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.selectAll(words);
      });
      this.bottomSheetRef.instance.keepSelectedEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.keepSelected(this.selectedWords);
      });
      this.bottomSheetRef.instance.removeSelectedEvent.pipe(takeUntil(this.unsubscribe$)).subscribe(($event) => {
        this.removeSelected(this.selectedWords);
      });
      this.bottomSheetRef.afterDismissed().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.bottomSheetOpen = false;
      });
    }
  }

  closeBottomSheet() {
    if (this.bottomSheetRef) {
      this.bottomSheetRef.dismiss();
    }
  }

  selectAll(words: any[]) {
    // this prevents duplicates
    this.selectedWords = [];
    words.forEach(word => {
      this.selectedWords.push(word);
      word.selected = true;
    });
  }

  deselectAll(words: any[]) {
    this.selectedWords = [];
    words.forEach(word => {
      word.selected = false;
    });
    this.closeBottomSheet();
  }

  keepSelected(words) {
    const plainWords: string[] = [];
    words.forEach(word => plainWords.push(word.word));
    this.openFilter('exactWords');
    const filterIndex = this.filters.findIndex(f => f.name === 'exactWords');
    this.filters[filterIndex].currentVal = plainWords;
    // this.workbook.filters = Object.assign(this.workbook.filters, {'exactWords': plainWords});
    this.workbook.filters = Object.assign(JSON.parse(JSON.stringify(this.workbook.filters)), {'exactWords': plainWords});
    this.selectedWords = [];
    this.search();
  }

  removeSelected(words) {
    const plainWords: string[] = [];
    this.workbook.filters = JSON.parse(JSON.stringify(this.workbook.filters));
    words.forEach(word => plainWords.push(word.word));
    this.openFilter('excludeWords');
    const filterIndex = this.filters.findIndex(f => f.name === 'excludeWords');
    // this.filters[filterIndex].currentVal = plainWords;
    if (!this.workbook.filters.excludeWords) {
      this.workbook.filters = Object.assign(this.workbook.filters, {'excludeWords': plainWords});
    } else {
      this.workbook.filters.excludeWords = this.workbook.filters.excludeWords.concat(plainWords);
    }
    this.filters[filterIndex].currentVal = this.workbook.filters.excludeWords;
    this.selectedWords = [];
    this.search();
  }
  getSelectedPlan() {
    this.planService
      .getPlan(JSON.parse(localStorage.profile).user_metadata.cusID)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.selectedPlan = result.name;
    });
  }

  reorderItems($event) {

    // console.log("Reorder items is called.")
    if ($event.container.id === $event.previousContainer.id) {
      // console.log($event)
      const words = JSON.parse(JSON.stringify(this.moveWords($event.container.data, $event.previousIndex, $event.currentIndex)));
      this.wordsSubject.next(words);

      // console.log(words)

      const orderedValue = words.map(_ => _.word);
      const exactWordsFilter = this.filters.find(_ => _.name === 'exactWords');
      if (exactWordsFilter && exactWordsFilter.currentVal?.length) {
        // console.log("Exact words")
        exactWordsFilter.currentVal = orderedValue;
      }
      if (this.workbook.filters.exactWords?.length) {
        this.workbook.filters.exactWords = orderedValue;
      }

      // This is happening because the user wants to maintain the order of the words
      // We are saving the order, word, and wordid
      this.workbook.wordOrder = words.map((word, index) => {
        return {
          order: index,
          word: word.word,
          wordid: word.wordid
        };
      });

      console.log(this.workbook.wordOrder);
    } else {
      transferArrayItem($event.previousContainer.data, $event.container.data, $event.previousIndex, $event.currentIndex);
    }
  }

  private moveWords(arr: any[], fromIndex: number, toIndex: number) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }


  private reorganizeWords(oldWords: any, newWords: any) {
    // Create a Set from the newWords array for quick lookups
    const newWordsSet = new Set(newWords.map(obj => obj.word));

    // Determine the common words based on oldWords order, but only store the word strings
    const commonWords = oldWords.filter(obj => newWordsSet.has(obj.word));

    // If no common words are found, return all newWords as they are
    if (commonWords.length === 0) {
      return newWords;
    }

    const commonWordsSet = new Set(commonWords.map(obj => obj.word));

    // Create a Map for quick access to objects in newWords by their word property
    const newWordsMap = new Map(newWords.map(obj => [obj.word, obj]));

    // Collect common words objects from newWords based on the commonWords order
    const commonWordsData = commonWords.map(obj => newWordsMap.get(obj.word))

    // Filter out common words from newWords to get the remaining new words
    const remainingNewWords = newWords.filter(obj => !commonWordsSet.has(obj.word));

    // Combine the arrays: common words first, then the remaining new words
    return [...commonWordsData, ...remainingNewWords];
  }
}

@Component({
  selector: 'app-word-bottom-sheet',
  templateUrl: './word-bottom-sheet/html/word-bottom-sheet.html',
  styleUrls: ['./word-bottom-sheet/word-bottom-sheet.scss']
})
export class WordBottomSheetComponent {
  @Output() removeSelectedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() keepSelectedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectAllEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deselectAllEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<WordBottomSheetComponent>) {}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  removeSelected() {
    this.removeSelectedEvent.emit();
    this._bottomSheetRef.dismiss();
  }

  keepSelected() {
    this.keepSelectedEvent.emit();
    this._bottomSheetRef.dismiss();
  }

  selectAll() {
    this.selectAllEvent.emit();
  }

  deselectAll() {
    this.deselectAllEvent.emit();
  }

  close() {
    this._bottomSheetRef.dismiss();
  }
}

@Component({
  selector: 'app-feedback-form',
  templateUrl: 'feedback/html/feedback-form.html'
})
export class FeedbackFormComponent implements OnDestroy {

  feedbackForm: UntypedFormGroup;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(public dialogRef: MatDialogRef<FeedbackFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private store: Store
  ) {
    this.dialogRef.disableClose = true;
    this.feedbackForm = this.formBuilder.group({
      userMessage: ['', [Validators.required, Validators.maxLength(320)
      ]],
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close({
      send: true,
      userMessage: this.feedbackForm.value.userMessage
    });
  }
}
