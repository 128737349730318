import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_DIR} from '../../environments/environment';

@Injectable()
export class ChatService {

  constructor(private http: HttpClient) {}

  sendMessage(threadId: string, message: string) {
    return new Observable(observer => {
      this.http
        .post(`${API_DIR}/chat`, {
            threadId,
            message
        })
        .subscribe(
          (response: any) => {
            observer.next(response.data);
          },
          error => {
            throw error;
          }
        );
    });
  }
}
