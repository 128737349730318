import {Component, EventEmitter, Input, Output, OnInit, OnChanges} from '@angular/core';
import {WordFilterComponent} from './word-filter.component';
import { toArray, take } from 'rxjs/operators';
@Component({
  selector: 'app-word-quick-checkbox-filter',
  templateUrl: './html/word-filter-quick-checkbox.html',
  styleUrls: [
    '../../../../../assets/css/main.css',
    '../../../../../assets/scss/fontawesome.scss',
    '../../../../../assets/scss/brands.scss',
    '../../../../../assets/scss/regular.scss',
    '../../../../../assets/scss/solid.scss',
    './word-filter.scss'

  ]
})

export class WordFilterQuickCheckboxComponent implements OnInit, OnChanges {
  @Input() filter: any;
  @Input() syllableCount: number;
  @Output() updateQuery: EventEmitter<any> = new EventEmitter<any>();
  searchFilter = {};
  selectedValues: any[] = [];
  ngOnChanges(changes: any) {
    if (changes.syllableCount) {
     this.filter.options.forEach(option => {
        if (option.sylNum > this.syllableCount + 1) {
            option.options.forEach(subOptions => {
              if (subOptions.selected) {
               this.toggleCheckedMultiple(option, subOptions);
              }
            });
        }
      });

      // if(changes.syllableCount.currentValue >= changes.syllableCount.previousValue){

      // } else if(changes.syllableCount.currentValue <= changes.syllableCount.previousValue)
    }
  }
  ngOnInit() {
    this.selectedValues = [];
    this.filter.options = this.filter.options.map(option => {
      option.selected = false;
      return option;
    });

    if (!this.syllableCount) {
      this.syllableCount = 3;
    }
    if (this.filter.options) {
      if (Array.isArray(this.filter.currentVal)) {
        this.filter.options.forEach((option) => {
          if (this.filter.currentVal.indexOf(option.value) >= 0) {
            option.selected = true;
          }

          if (option.selected) {
            this.selectedValues.push(option.value);
          }
        });

        // this.updateQueryEvent(null);
      } else {
        this.filter.options.forEach((option, index, array) => {
            if ((this.filter.currentVal && this.filter.currentVal[option.name] ) && this.filter.currentVal[option.name].length > 0) {

              this.filter.currentVal[option.name].forEach(type => {
                const index = option.options.findIndex(oo => oo.name === type);
                option.options[index].selected = true;
              });
              Object.keys(this.filter.currentVal).forEach(key => {
                this.searchFilter[key] = this.filter.currentVal[key];
              });

            }
        });
        // this.filter.options.forEach((filter, index, array) => {
        //   if (filter.sylNum >= this.syllableCount + 1) {

        //     this.searchFilter[filter.name] = [];
        //     //  filter.options = filter.options.map((option) => {
        //     //    if (option.selected) {
        //     //      option.selected = false;
        //     //    }
        //     //  });
        //   }
        // });
        let values = Object.values(this.searchFilter);
        let keys = Object.keys(this.searchFilter);
        let test = values.every((value: any) => value.length === 0);
        if (test  && !(this.filter.currentVal)) {
          this.filter.options.forEach(option => {
            if (!option.options) {
              return;
            }

            option.options.forEach((subOption) => {
              subOption.selected = false;
            });
          });
        } else if (!test && this.filter.currentVal) {
          for (const [key, value] of Object.entries(this.filter.currentVal)) {
            let option = this.filter.options.find((option) => option.name === key);
            option.options.forEach(subOptions => {
              // @ts-ignore
              if (value.includes(subOptions.name)) {
                subOptions.selected = true;
              } else {
                subOptions.selected = false;
              }
            });
          }

        }
        // this.updateQueryEvent(this.searchFilter);
      }



    }
  }

  toggleCheckedSingle(option: any) {
    option.selected = !option.selected;
    this.selectedValues = [...[], ...this.selectedValues];
    const index = this.selectedValues.indexOf(option.value);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
    } else {
      this.selectedValues.push(option.value);
    }
    this.updateQueryEvent(null);
  }

  toggleCheckedMultiple(category: any, option: any) {
    option.selected = !option.selected;
    this.searchFilter = JSON.parse(JSON.stringify((this.searchFilter)));
    // if (this.searchFilter[category.name]) {
    //   if (this.searchFilter[category.name].includes(option.value)) {
    //     this.searchFilter[category.name].splice(this.searchFilter[category.name].indexOf(option.value), 1);
    //   } else {
    //     this.searchFilter[category.name].push(option.value);
    //   }
    // } else {
    //   this.searchFilter[category.name] = [option.value];
    // }
    // this.updateQueryEvent(this.searchFilter);

      this.searchFilter[category.name] = category?.options.filter((option) => option.selected).map(({value}) => value);
      this.updateQueryEvent(this.searchFilter);
  }

  updateQueryEvent(filter: any) {
    if (filter) {
      this.updateQuery.emit({[this.filter.name]: filter});
    } else {
      this.updateQuery.emit({[this.filter.name]: this.selectedValues});
    }
  }
}
