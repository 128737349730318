import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { WorkbookBuilderComponent } from './workbook-builder.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<WorkbookBuilderComponent> {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component: WorkbookBuilderComponent): Observable<boolean> | Promise<boolean> | boolean {
    if (component.hasUnsavedChanges()) {
      return component.showUnsavedChangesDialog();
    }
    return true;
  }
}