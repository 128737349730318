import { Store } from '@ngrx/store';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { WorkbooksService } from '../../../services/workbooks.service';
import { WordsService } from '../../../services/words.service';
import { CourseService } from '../../../services/courses.service';
import { WorkbookBuilderWordsComponent } from './words.component';
import { CourseWorkbooksService } from '../../../services/course-workbooks.service';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { DialogData } from '../../../../activities/timed-reading/timed-reading.component';
import { SubscriptionMenuComponent } from '../../../../Settings/Account/account.component';
import { TutorialDialogComponent } from '../../support/support.component';
import { filter } from 'rxjs/operators';
import { ListenerService } from '../../../services/listener.service';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import {
  getAllNonAndSubscriptionCourses, getSetCourseData, getWorkbook, isSetCourseLoaded, selectGetAllCourseWorkbook, selectGetNonOrSubscribedCoursesById,
  selectGetPostResponseByWorkbookId, selectGetWorkbookFiles, selectGetWorkbookPassages,
  selectGetWorkbookPhrases, selectGetWorkbookSentences
} from '../../../store';
import { RemoveWorkbookFromBuilder, UpdateWorkbook } from '../../../store/workbook/workbook.actions';

import { ClearWords, PostWords, SetToEmpty } from '../../../store/words/words.actions';
import { SetCurriculumCourse } from '../../../store/set-course/set-course.actions';
import { UpdateByWorkbook } from '../../../store/course-workbook/course-workbook.actions';
@Component({
  selector: 'app-workbook-builder',
  templateUrl: './html/workbook-builder.html',
  styleUrls: [
    '../../../../assets/css/main.css',
    '../../../../assets/scss/fontawesome.scss',
    '../../../../assets/scss/brands.scss',
    '../../../../assets/scss/regular.scss',
    '../../../../assets/scss/solid.scss',
    './workbook-builder.scss'

  ]
})

export class WorkbookBuilderComponent implements OnInit, OnDestroy {
  @ViewChild('words', { static: false }) words: WorkbookBuilderWordsComponent;
  @ViewChild('tabGroup') private tabGroup: ElementRef;

  newWorkbook = false;
  currentView = 'words';
  workbook: any;
  workbookId: string;
  editting = false;
  previousUrl: string;
  nameChangeSubject: Subject<any> = new Subject<any>();
  descriptionChangeSubject: Subject<any> = new Subject<any>();
  heading: string;
  videoLink: string;
  selectedTabIndex: number;
  public isFromLibrary: boolean;
  public isLoading = false;
  public maxlengthDes: boolean;
  public maxlengthName: boolean;
  public _iOSDevice = /iPhone|iPod|iPad|/.test(navigator.platform);
  private courseWorkbooks: any;
  buildWorkbookForm: UntypedFormGroup;
  helpImage: any;
  private unsubscribe$: Subject<void> = new Subject();
  workbookPhrases: any;
  workbookPassages: any;
  workbookSentences: any;
  workbookFiles: any;
  updatedWorkbook: any;
  isSaveBtnClicked: boolean;
  courseworkbooks: any;
  userId: any;
  public changeNameModalClosed = true;
  currentCourse: any;

  public originalWorkbookState: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workbookService: WorkbooksService,
    private wordsService: WordsService,
    private courseWorkbooksService: CourseWorkbooksService,
    private courseService: CourseService,
    private dialog: MatDialog,
    private listenerService: ListenerService,
    private dataService: DataService,
    private store: Store,
    private formBuilder: UntypedFormBuilder
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.unsubscribe$))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
        // Check to see if the user is on the right plan level, if not redirect them to the curriculum page
        this.listenerService.getPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plan) => {
          if (plan) {
            if (plan === "Demo" || plan === 'Demo v2' || plan === 'Hero v2') {
              this.router.navigate(['/my-curriculum']);
            }
          }
        });
      });
    const idIndex = this.route.url['value'].length - 1;
    this.workbookId = this.route.url['value'][idIndex].path;
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.listenerService.listener().pipe(takeUntil(this.unsubscribe$)).subscribe((callback) => {
      if (callback) {
        const parts = callback.split(':');
        if (parts && parts.length > 1) {
          this.workbookId = parts[1];
        }
      }
    });
    this.userId = JSON.parse(localStorage.getItem('profile')).user_metadata.uid;

    this.store.select(selectGetAllCourseWorkbook)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(courseworkbooks => this.courseworkbooks = courseworkbooks);

    this.store.select(getSetCourseData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(currentCourse => {
        this.currentCourse = JSON.parse(JSON.stringify(currentCourse));
      });

    this.buildWorkbookForm = this.formBuilder.group({
      name: ['', [Validators.maxLength(65), Validators.required, (control: AbstractControl) => {
        const exist = !!this.courseworkbooks.find(item => (item.workbook ? item.workbook.name : item.name) === control.value && item.workbookId !== this.workbookId);
        if (exist) {
          return { duplicate: true };
        }
        return false;
      }]],
      description: ['', [Validators.maxLength(300)]]
    });
    this.buildWorkbookForm.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(values => {
        this.workbook.name = values.name
        this.workbook.desc = values.description
      })
  }

  ngOnInit() {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.helpImage = path + 'help-header.png';
    });

    this.heading = 'Workbook Builder Page';
    this.videoLink = 'https://videos.sproutvideo.com/embed/449cd9b41510efc5cd/2992813709b12d5b?type=sd';
    this.currentView = this.route.url['value'][1].path;
    this.route
      .params
      .pipe(
        take(1),
        switchMap(params => {
          if (params.selectedTabIndex >= 0) {
            this.selectedTabIndex = params.selectedTabIndex;
          }

          if (params['isFromLibrary']) {
            this.isFromLibrary = params['isFromLibrary'].toLowerCase() === 'true';
          }
          this.workbookId = params['workbookId'];
          if (this.workbookId !== 'new') {
            // return this.workbookService.get(this.workbookId);
            return this.store.select(getWorkbook, { id: this.workbookId });
          } else {
            this.newWorkbook = true;
            let filters: any = {};
            if (params['userKnows'] === 'true') {
              filters = {
                isPhonetic: true,
                exactWords: [],
                isNonsense: ['real']
              };
            } else if (params['userKnows'] === 'false') {
              filters = {
                isPhonetic: true,
                isNonsense: ['real'],
                nLetters: { gte: 3, lte: 7 },
                nSyllables: { gte: 1, lte: 3 },
                exactTiles: []

              };
            } else if (params['chat'] === 'true') {
              filters = {
                isPhonetic: true,
                isNonsense: ['real'],
              };
            }
            // Enter a workbook description (optional)
            return new Observable(observer => observer.next({ name: 'Untitled Workbook', desc: "", filters: filters }));
          }
        }),
        switchMap(workbook => {
          if (workbook) {
            this.workbook = JSON.parse(JSON.stringify(workbook));
            if (this.workbook.desc === 'Enter a workbook description (optional)') {
              this.workbook.desc = '';
            } else if (this.workbook.desc !== 'Enter a workbook description (optional)') {
              this.workbook.desc = this.workbook.desc.trim();
            }
            if (this.workbookId === 'new') {
              this.openWorkbookNameDialog();
            }
            this.buildWorkbookForm.setValue({
              name: this.workbook.name,
              description: this.workbook.desc
            });
            
            this.originalWorkbookState = JSON.stringify(this.workbook);
            return combineLatest([
              this.store.select(isSetCourseLoaded),
              this.store.select(getAllNonAndSubscriptionCourses)
            ]);
          }
          return of([null, null]);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([isLoadedSetCourse, allCourses]) => {
        if (!isLoadedSetCourse && allCourses?.length) {
          let course = allCourses[0];

          // Check to see if this is causing loading screen issues
          const courseStorageValue = localStorage.getItem('previousSelectedCourse');
          if (courseStorageValue) {
            course = JSON.parse(courseStorageValue);
          }
          this.store.dispatch(new SetCurriculumCourse({
            course,
            redirect: false
          }));
        }
      });

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(param => {
      if (param.type === 'words') {
        this.selectedTabIndex = 0;
      } else if (param.type === 'sentences') {
        this.selectedTabIndex = 1;
      } else if (param.type === 'files') {
        this.selectedTabIndex = 2;
      }
    });

    this.store.select(selectGetAllCourseWorkbook)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(courseWorkbooks => {
        this.courseWorkbooks = courseWorkbooks;
      });

    // This is to check if the "Words" in the local store have been cleared.
    this.store.select(selectGetPostResponseByWorkbookId, { id: this.workbookId })
      .pipe(take(1))
      .subscribe(result => {
        if (result && result.length === 0) {
          // We will then set this to null
          this.store.dispatch(new SetToEmpty({ id: this.workbookId }));
        }
      });

    /*
      This gets called everytime the workbook is opened (and closed).
      The first time it is opened, "Words" store, in the local store, will be empty.
      This will then trigger the "PostWords" action to be dispatched. (will grab the 400 words from the backend and store it in the local store)

      When the workbook is closed, the "Words" store will be cleared. This prevents this from being called again.

      The next time the workbook is opened, the "Words" store should be completely empty and the postResponse should be set to null.
    */
    this.store.select(selectGetPostResponseByWorkbookId, { id: this.workbookId })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {

        console.log("This is the result of the workbookId: " + this.workbookId);
        if (!result && !Array.isArray(result)) {

          console.log("Checking to see if recalled. " + this.workbookId)
          console.log(this.workbook.filters);
          this.store.dispatch(new PostWords({
            id: this.workbookId,
            filter: this.workbook.filters
          }));
        }
      });

    combineLatest([
      this.store.select(selectGetWorkbookPhrases),
      this.store.select(selectGetWorkbookPassages),
      this.store.select(selectGetWorkbookSentences),
      this.store.select(selectGetWorkbookFiles)
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([workbookPhrases, workbookPassages, workbookSentences, workbookFiles]) => {
        this.workbookPhrases = workbookPhrases;
        this.workbookPassages = workbookPassages;
        this.workbookSentences = workbookSentences;
        this.workbookFiles = workbookFiles;

        console.log('workbook', this.workbook);
      });
  }

  openWorkbookNameDialog(): void {
    this.changeNameModalClosed = false;
    const dialogRef = this.dialog.open(WorkbookNameModalComponent, {
      panelClass: 'import-collection-modalbox',
      data: {
        workbook: this.workbook
      }
    });
    dialogRef.afterClosed().subscribe((newWorkbook) => {
      this.changeNameModalClosed = true;
      this.isLoading = true;
      if (newWorkbook) {
        this.workbook = newWorkbook;
        this.words.save(false, this.isFromLibrary);
        this.originalWorkbookState = JSON.stringify(this.workbook);
      } else {
        this.router.navigate([`my-curriculum`]);
      }
    });
  }
  openDialog(): void {
    // const dialogRef = this.dialog.open(TutorialDialogComponent, {
    //   disableClose: true,
    //   data: {
    //     title: this.heading,
    //     link:  this.videoLink
    //   },
    // });
    // dialogRef.afterClosed().subscribe(result => {
    // });
    // dialogRef.backdropClick().subscribe((result) => {
    //   this.dialog.open(TutorialDialogComponent, {
    //     data: {
    //       title: this.heading,
    //       link:  this.videoLink
    //     },
    //   });
    // });

    const dialogRef = this.dialog.open(TutorialDialogComponent, {
      panelClass: "tutorial_dialog_modal",
      data: {
        title: this.heading,
        link: this.videoLink
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  hasUnsavedChanges(): boolean {
    return JSON.stringify(this.workbook) !== this.originalWorkbookState;
  }

  showUnsavedChangesDialog(): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
        panelClass: 'unsaved-changes-panel',
        disableClose: true,
        data: { message: 'You have unsaved changes. Do you want to leave without saving?' },
      });
  
      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }

  updatingWorkbook(event: any) {
    this.updatedWorkbook = JSON.parse(JSON.stringify(event));
  }


  askDelete(id: string) {
    const dialogRef = this.dialog.open(DeleteWorkbookDialogComponent, {
      height: '170px',
      width: '328px',
      panelClass: 'custom-confirm-dialog',
      data: {
        id: id,
        newWorkbook: this.newWorkbook,
        router: this.router
      },
    });

    dialogRef.componentInstance.functions = {
      deleteWorkbook: (id: string) => {
        // this.workbookService
        //   .delete(id)
        //   .pipe(take(1))
        //   .subscribe(() => {
        //     dialogRef.close();
        //     this.router.navigate([`my-curriculum`]);
        //   });

        const found = this.courseWorkbooks.find(item => {
          return item.workbookId === id;
        });
        this.store.dispatch(new RemoveWorkbookFromBuilder({
          workbookId: id,
          courseWorkbookId: found?._id,
        }));
      }
    };
  }
  ngOnDestroy(): void {
    if (this.workbookId !== 'new' && this.workbook?._id && !this.isSaveBtnClicked) {
      let updatedWorkbook: any = {};
      if (this.workbookPhrases.length) {
        updatedWorkbook.hasPhrases = true;
      } else {
        updatedWorkbook.hasPhrases = false;
      }

      if (this.workbookSentences.length) {
        updatedWorkbook.hasSentences = true;
      } else {
        updatedWorkbook.hasSentences = false;
      }

      if (this.workbookPassages.length) {
        updatedWorkbook.hasPassages = true;
      } else {
        updatedWorkbook.hasPassages = false;
      }

      if (this.workbookFiles.length) {
        updatedWorkbook.hasFiles = true;
      } else {
        updatedWorkbook.hasFiles = false;
      }

      console.log("Workbook is going to be updated");
      console.log(updatedWorkbook);
      console.log(this.workbook);
      this.store.dispatch(new UpdateWorkbook({
        _id: this.workbook._id,
        data: updatedWorkbook,
      }));

    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  returnHome() {
    this.router.navigate([`my-curriculum`]);
  }
  toggleEdit() {
    this.editting = !this.editting;
  }

  updateWorkbook(workbook: any) {
    this.workbookService.update(workbook, workbook._id);
    this.toggleEdit();
  }

  // onNameChanged($event: any) {
  //   const name = this.buildWorkbookForm.get('name');
  //   this.maxlengthName = name.status === "INVALID"  ? true : false;

  //   this.nameChangeSubject.next($event.target.value);
  // }

  finishEditing(event: KeyboardEvent): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    target.blur();
  }

  updateWorkbookName(): void {
    const newName = this.buildWorkbookForm.get('name').value;
    this.workbook.name = newName;
  }

  updateWorkbookDescription(): void {
    const newDescription = this.buildWorkbookForm.get('description').value;
    this.workbook.desc = newDescription;
  }

  // onDescriptionChanged($event: any) {
  //   const name = this.buildWorkbookForm.get('description');
  //   this.maxlengthDes = name.status === "INVALID" ? true : false;

  //   this.descriptionChangeSubject.next($event.target.value);
  // }

  tabClick(tab) {
    const params = { ...this.route.params['value'] };
    const workbookId = params.workbookId;
    delete params.workbookId;

    this.selectedTabIndex = tab.index;

    if (tab.index === 0) {
      this.router.navigate([`workbook-builder`, workbookId, { ...params, type: 'words' }]);
    } else if (tab.index === 1) {
      this.router.navigate([`workbook-builder`, workbookId, { ...params, type: 'sentences' }]);
    } else if (tab.index === 2) {
      this.router.navigate([`workbook-builder`, workbookId, { ...params, type: 'files' }]);
    }
  }

  scrollToBottom() {
    // console.log("Should scroll.")

    let tabs = (this.tabGroup as any)._elementRef.nativeElement.querySelector('.mat-mdc-tab-body-wrapper');
    let wordsTab = tabs.firstChild;
    let scrollableView = wordsTab.querySelector('.mat-mdc-tab-body-content');

    scrollableView.scrollTo({ top: scrollableView.scrollHeight, behavior: 'smooth' });
  }
}

@Component({
  selector: 'app-workbook-name-modal',
  templateUrl: 'html/workbook-name-modal.html'
})
export class WorkbookNameModalComponent implements OnDestroy {

  // public workbookName = '';
  // public maxlengthName: boolean;

  workbookNameForm: UntypedFormGroup;
  courseworkbooks: any;
  private unsubscribe$: Subject<void> = new Subject();

  constructor(public dialogRef: MatDialogRef<WorkbookNameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private store: Store
  ) {
    this.dialogRef.disableClose = true;
    this.store.select(selectGetAllCourseWorkbook)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(courseworkbooks => this.courseworkbooks = courseworkbooks);

    this.workbookNameForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(65)
      ]],
      description: ['', [Validators.maxLength(160)]]
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  getName(event): void {
  }

  onSave(): void {
    this.data.workbook.name = this.workbookNameForm.value.name;
    this.dialogRef.close(this.data.workbook);
  }
}

@Component({
  selector: 'app-delete-workbook-menu',
  templateUrl: 'html/delete-workbook-menu.html',
  styles: [
    `:host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }`
  ]
})
export class DeleteWorkbookDialogComponent {
  functions;

  constructor(
    public dialogRef: MatDialogRef<DeleteWorkbookDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  deleteWorkbook() {
    if (this.data.newWorkbook) {
      this.data.router.navigate([`my-curriculum`]);
      this.dialogRef.close();

    } else {
      this.functions.deleteWorkbook(this.data.id);
      this.dialogRef.close();
    }
  }
}

@Component({
  selector: 'app-unsaved-changes-dialog',
  templateUrl: 'html/unsaved-changes.html',
})
export class UnsavedChangesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
