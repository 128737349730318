import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkbookBuilderComponent } from './workbook-builder.component';
import { AuthGuard } from '../../../services/auth-guard.service';
import { WorkbookResolver } from '../../../resolvers/workbook.resolver';
import { WorkbookPassagesResolver } from '../../../resolvers/workbook-passages.resolver';
import { AllMyLibraryWorkbooksResolver } from '../../../resolvers/all-my-library-workbooks.resolver';
import { WorkbookPhrasesResolver } from '../../../resolvers/workbook-phrases.resolver';
import { WorkbookSentencesResolver } from '../../../resolvers/workbook-sentences.resolver';
import { AllPhrasesResolver } from '../../../resolvers/all-phrases.resolver';
import { AllPassagesResolver } from '../../../resolvers/all-passages.resolver';
import { AllSentencesResolver } from '../../../resolvers/all-sentences.resolver';
import { AllFilesResolver } from '../../../resolvers/all-files.resolver';
import { WorkbookFilesResolver } from '../../../resolvers/workbook-files.resolver';
import { WordPairingsResolver } from '../../../resolvers/word-pairings.resolver';
import { SettingsResolver } from '../../../resolvers/settings.resolver';
import { AllCoursesResolver } from '../../../resolvers/all-courses.resolver';
import { AllSubscribedCoursesResolver } from '../../../resolvers/all-subscribed-courses.resolver';
import { PlanResolver } from '../../../resolvers/plan.resolver';
import { UnsavedChangesGuard } from './unsaved-changes.guard';

const workbookBuilderRoutes: Routes = [
  { 
  path: 'workbook-builder/:workbookId', 
  canActivate: [AuthGuard], 
  canDeactivate: [UnsavedChangesGuard],
  component: WorkbookBuilderComponent,
  // runGuardsAndResolvers: 'always',
  resolve: {
    allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
    allCoursesLoaded: AllCoursesResolver,
    allMyLibraryWorkbooksLoaded: AllMyLibraryWorkbooksResolver,
    isWorkbookLoaded: WorkbookResolver,
    allPhrasesLoaded: AllPhrasesResolver,
    allPassagesLoaded: AllPassagesResolver,
    allSentencesLoaded: AllSentencesResolver,
    allFilesLoaded: AllFilesResolver,
    isWorkbookPassagesLoaded: WorkbookPassagesResolver,
    isWorkbookPhrasesLoaded: WorkbookPhrasesResolver,
    isWorkbookSentencesLoaded: WorkbookSentencesResolver,
    isWorkbookFilesLoaded: WorkbookFilesResolver,
    isWordPairingsLoaded: WordPairingsResolver,
    allSettingsLoaded: SettingsResolver,
    planLoaded: PlanResolver 

  }
},
{
  path: 'workbook-builder/:courseWorkbookId',
  canActivate: [AuthGuard],
  canDeactivate: [UnsavedChangesGuard],
  component: WorkbookBuilderComponent,
  resolve: {
    allSubscribedCoursesLoaded: AllSubscribedCoursesResolver,
    allCoursesLoaded: AllCoursesResolver,
    allMyLibraryWorkbooksLoaded: AllMyLibraryWorkbooksResolver,
    isWorkbookLoaded: WorkbookResolver,
    allPhrasesLoaded: AllPhrasesResolver,
    allPassagesLoaded: AllPassagesResolver,
    allSentencesLoaded: AllSentencesResolver,
    allFilesLoaded: AllFilesResolver,
    isWorkbookPassagesLoaded: WorkbookPassagesResolver,
    isWorkbookPhrasesLoaded: WorkbookPhrasesResolver,
    isWorkbookSentencesLoaded: WorkbookSentencesResolver,
    isWorkbookFilesLoaded: WorkbookFilesResolver,
    isWordPairingsLoaded: WordPairingsResolver,
    allSettingsLoaded: SettingsResolver,
    planLoaded: PlanResolver 

  }
}
];

@NgModule({
  imports: [
    RouterModule.forChild(workbookBuilderRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class WorkbookBuilderRoutingModule {}
